<script setup>
const { data: profile } = useAuth();

useHead({
    bodyAttrs: {
        class: 'font-sans antialiased text-white bg-slate-800 min-h-screen',
        tagDuplicateStrategy: 'replace',
    },
});

const userProfile = ref();
const toggleProfileLayer = ref(false);

function clickedProfile() {
    toggleProfileLayer.value = !toggleProfileLayer.value;
}

useClickOutside(userProfile, () => {
    if (toggleProfileLayer.value) {
        clickedProfile();
    }
});

function emitProfileItem(item) {
    toggleProfileLayer.value = !item.close;
}

onMounted(() => {
    if (document.querySelector('helpspace-widget')) {
        window.HelpWidget('destroy');
    }
});
</script>

<template>
    <div class="bg-slate-800">
        <MemberAppHeader is-active-member>
            <template #profile>
                <div ref="userProfile">
                    <button
                        type="button"
                        class="hover:border-brand border-2 border-transparent rounded-full overflow-hidden"
                        :class="{ 'border-brand': toggleProfileLayer }"
                        @click="clickedProfile"
                    >
                        <MemberAvatar
                            :src="profile?.avatar?.src"
                            :alt="`${profile?.firstname} ${profile?.lastname}`"
                        />
                    </button>
                    <transition>
                        <MemberHeaderProfileLayer
                            v-if="toggleProfileLayer"
                            @item:clicked="emitProfileItem"
                        />
                    </transition>
                </div>
            </template>
        </MemberAppHeader>

        <main class="min-h-screen relative z-10">
            <NuxtPage />
        </main>
    </div>
    <MemberFooter is-active-member />
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
